import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

const ContactPage = () => {
  return (
    <Layout>
      <div className="columns is-centered is-mobile">
        <div className="column is-two-thirds">
          <h1 className="title">Contact</h1>
          <div className="columns">
            <div className="column is-2">
              <figure className="image is-128x128">
                <StaticImage
                  src="../images/steve.jfif"
                  loading="eager"
                  alt="Steve"
                  width={381}
                  height={380}
                  placeholder="blurred"
                  layout="constrained"
                  imgClassName="image is-rounded"
                />
              </figure>
            </div>
            <div className="column content">
              {" "}
              <p>
                Contact via email:{" "}
                <a href="mailto:steve.rollason@mindmapuk.com">
                  steve.rollason@mindmapuk.com
                </a>
              </p>
            </div>
          </div>
          <div className="content">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit vero
              alias eius, fugiat quam facere officia sequi neque sint incidunt!
            </p>
          </div>
          <form name="contact" netlify>
            <p className="hidden">
              <label>
                Don’t fill this out if you’re human:
                <input name="bot-field" />
              </label>
            </p>
            <div className="field">
              <label className="label" for="name">
                Name
                <div className="control">
                  <input
                    id="name"
                    className="input"
                    type="text"
                    placeholder="John Doe"
                  />
                </div>
              </label>
            </div>

            <div className="field">
              <label className="label" for="email">
                Email
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="email"
                    className="input"
                    type="email"
                    placeholder="Email"
                    value=""
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>
              </label>
            </div>

            <div className="field">
              <label className="label" for="subject">
                Subject
                <div className="control">
                  <div className="select">
                    <select id="subject">
                      <option>Inquiry</option>
                      <option>Booking</option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="field">
              <label className="label" for="message">
                Message
                <div className="control">
                  <textarea
                    id="message"
                    className="textarea"
                    placeholder=""
                  ></textarea>
                </div>
              </label>
            </div>

            <div className="field is-grouped">
              <div className="control">
                <button className="button is-link" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
